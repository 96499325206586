<template>
  <div class="P-filters">
    <div class="P-input-block">
      <ott-input
        v-model="filterModel.search"
        @timeoutInput="filterData('search')"
        :timeoutInput="true"
        label="Search"
        prependInnerIcon="mdi mdi-magnify neutral--text"
      />
    </div>

    <div class="P-input-block G-align-center justify-space-between">
      <ott-checkbox
        v-model="filterModel.allResellers"
        @updated="filterData('allResellers')"
        label="All"
        checkboxClass="me-2"
      />

      <div class="w-80">
        <ott-select
          v-model="filterModel.resellers"
          @emitFunc="filterData('resellers')"
          :clear="true"
          :items="resellersList"
          :multiple="true"
          :persistentHint="true"
          label="Select Resellers"
          selected-text="name"
        ></ott-select>
      </div>
    </div>

    <div class="P-input-block G-date-picker G-flex G-align-center">
      <DatePicker
        v-model="filterModel.date"
        :masks="{ input: ottDateFormat }"
        :popover="{ visibility: 'click' }"
        :max-date="newDateWithMainUserTZ"
        :is-dark="$vuetify.theme.dark"
        @input="filterData"
        locale="en"
        is-range
        class="ott-date-picker"
      >
        <template v-slot="{ inputValue, inputEvents }">
          <div class="ott-date-picker-cont Ott-input-class:">
              <div class="G-calendar-input">
                  <v-text-field
                      v-model="inputValue.start"
                      v-on="inputEvents.start"
                      outlined
                      dense
                      height="38"
                      label="Start Date"
                  />
                  <span
                      class="P-date-icon neutral--text"
                      @click="resetData('date')"
                      :class="
                        !inputValue.start
                          ? 'mdi mdi-calendar-blank-outline P-calendar'
                          : 'icon-cancel'
                      "
                  ></span>
              </div>
              <span class="pick-to neutral--text">To</span>
              <div class="G-calendar-input">
                  <v-text-field
                      v-model="inputValue.end"
                      v-on="inputEvents.end"
                      outlined
                      dense
                      height="38px"
                      label="End Date"
                  />
                  <span
                      @click="resetData('date')"
                      :class="
                          !inputValue.start
                          ? 'mdi mdi-calendar-blank-outline P-calendar'
                          : 'icon-cancel'
                      "
                      class="P-date-icon neutral--text"
                  ></span>
              </div>
          </div>
        </template>
      </DatePicker>  
    </div>

    <selected-filters-list v-if="filterModel.filterCount.length" />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import OttInput from "@/components/vuetifyComponents/OttInput";
import SelectedFiltersList from "./SelectedFiltersList";
import DatePicker from "v-calendar/src/components/DatePicker";
import ConvertTimeZoneMixin from "@/mixins/ConvertTimeZoneMixin";
import DateFormatMixin from "@/mixins/DateFormatMixin";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";

export default {
  name: "FilterData",
  components: { OttInput, SelectedFiltersList, DatePicker, OttSelect, OttCheckbox },
  mixins: [ ConvertTimeZoneMixin, DateFormatMixin ],
  computed: {
    ...mapState({
      filterModel: (state) => state.clientActivityModule.filterModel,
      filter: (state) => state.clientActivityModule.filterData,
      resellersList: (state) => state.providers.resellersList,
    }),
  },

  methods: {
    ...mapActions({
      getClientActivityList: "clientActivityModule/getClientActivityList",
    }),

    ...mapMutations({
      filterClientActivity: "clientActivityModule/filterClientActivity",
      setFilterPage: 'clientActivityModule/setFilterPage',
      sortColumn: 'clientActivityModule/sortColumn',
    }),

    async filterData(type) {
      switch (type) {
        case 'search':
          if (!this.filterModel.search) {
            this.filterModel.resetCurrentData(type)
          }
          break;

        case 'allResellers':
          if (!this.filterModel.allResellers) {
            this.filterModel.resetCurrentData(type)
          }
          break;
        
        case 'resellers':
          if (!this.filterModel.resellers.length) {
            this.filterModel.resetCurrentData(type)
          }
          break;
      }

      this.setFilterPage(1);
      this.filterClientActivity();
      this.filterModel.getFilterCount();
      this.sortColumn();
      await this.getClientActivityList(this.filter);
      this.$emit('resetSelectedList')
    },

    async resetData(type) {
      this.filterModel.resetCurrentData(type);
      this.filterModel.getFilterCount();
      this.filterClientActivity()
      await this.getClientActivityList(this.filter);
      this.$emit('resetSelectedList')
    },
  },
};
</script>

<style scoped lang="scss">
  .P-input-block {
    margin-bottom: 20px;
  }

  .w-80 {
    width: 80%;
  }
</style>

