<template>
  <div class="P-activity-list">
    <div 
      class="G-page-component" 
      :class="{ 
        'P-filter-open': isOpenFilter,
        'pa-0': $route.name === 'Search',
      }"
    >
      <div 
        :class="{ 'mb-0': $route.name === 'Search' }" 
        class="G-page-title-block G-flex G-justify-between G-align-center"
      >
        <div class="G-component-title d-flex">
          <h3 
            :class="{ 'P-small-header': $route.name === 'Search' }" 
            class="secondary--text"
          >
            Client Logs
          </h3>
        </div>
      </div>
      <div class="G-component-header page-background">
        <div class="G-table-actions G-flex G-align-center">
          <div class="action-cont-first G-flex G-align-center">
            <ott-checkbox
              :indeterminate="selectNotAll"
              :dataVal="selectAllList"
              tooltip="Select all visibles"
              @changeCheckbox="selectAllVisibles"
            />
            <div class="G-select-all">
              <OttCheckbox
                tooltip="Select all"
                v-model="isSelectedAll"
                @changeCheckbox="selectAll"
              />
            </div>
          </div>
          <div class="G-excel-btn">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div class="P-settings" v-bind="attrs" v-on="on">
                  <span class="mdi mdi-file-excel neutral--text P-icon" />
                </div>
              </template>
              <span>Download Excel</span>
            </v-tooltip>
          </div>
          <OttHideShowColumnsOption
            v-if="showColumnConfig"
            :column-config="columnConfigData"
            :is-default="isDefault"
            @resetDataSettingsTable="resetDataTableSettings"
            @updateColumnsFromCheck="updateColumnsFromCheck"
            @hoverColumnConfig="hoverColumnConfig"
            @leaveColumnConfig="leaveColumnConfig"
            class="P-users-columns"
          />
        </div>
        <div v-if="$route.name !== 'Search'">
          <div
            class="G-filter-btn"
            @click="toggleOpenFilter"
            :class="{ 'P-filter-open': isOpenFilter }"
          >
            <span class="mdi mdi-filter-variant"></span>
            <p>Filter</p>
            <div
              v-if="filterModel?.filterCount.length"
              class="active-filter-count"
            >
              <span>{{ filterModel?.filterCount.length }}</span>
            </div>
            <span class="mdi mdi-menu-right"></span>
          </div>
        </div>
      </div>
      <div
        class="G-component-body page-background"
      >
        <div v-if="$route.name !== 'Search'" class="G-sub-block-title">
          <h3 class="secondary--text">Client Activity List</h3>
        </div>
        
        <OttUniversalTable
          v-if="(!loading && $route.name !== 'Search') || $route.name === 'Search'"
          :column-config="columnConfigData"
          :selected-arrow-list="selectedArrowList"
          :data="clientActivity.dataTable"
          :is-sub-table="false"
          :is-multi-select="true"
          :fixed-first-and-last-column="true"
          :main-data="clientActivity.dataTable"
          :column-with-tree-increment="tableColumnWidth"
          :is-total-sum="false"
          @updateColumns="updateColumns"
          @saveColumnChangesFromDrag="updateColumnsFromDrag"
          @selectRow="selectRow"
          @sortData="sortColumnData"
          @updateColumnWith="updateColumn"
        />

        <OttPaginationForTable
          v-if="clientActivityResult && !loading"
          v-model="rowsPerPage"
          :data-count="clientActivity.dataCount"
          :available-data-count="clientActivity.dataTable.length"
          :current-page="clientActivity.currentPage"
          :page-limit="clientActivity.totalPages"
          @update="updateLimit"
          @updatePage="updateClientActivity"
        />
        <NoDataInTable v-else />
      </div>
    </div>
    <div
      v-if="$route.name !== 'Search'"
      :class="{ 'P-filter-open': isOpenFilter }"
      class="G-filter-component page-background"
    >
      <div class="G-filter-header G-flex G-align-center G-justify-between">
        <p class="secondary--text">Filter by</p>
        <span class="mdi mdi-close neutral--text" @click="closeFilter"> </span>
      </div>
      <div class="G-filter-body">
        <FilterData @resetSelectedList="resetSelectedList" />
      </div>
      <div class="G-filter-footer">
        <ott-button
          class="primary-lg-btn"
          text="Reset Filters"
          :click="resetFilterData"
        >
        </ott-button>
      </div>
    </div>

    <PageLoader v-if="(isTableLoading || loading) && $route.name !== 'Search'" />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
// mixins
import OttUniversalTableForComponentsMixin from "@/components/OttUniversalTable/OttUniversalTableForComponentsMixin";
import FilterMixin from "@/mixins/FilterMixin";
import ColumnConfigMixin from "@/mixins/ColumnConfigMixin";
// components
import OttButton from "@/components/vuetifyComponents/OttButton";
import OttCheckbox from "@/components/vuetifyComponents/OttCheckbox";
import OttRadio from "@/components/vuetifyComponents/OttRadio";
import OttSelect from "@/components/vuetifyComponents/OttSelect";
import OttHideShowColumnsOption from "@/components/OttUniversalTable/OttHideShowColumnsOption";
import OttUniversalTable from "@/components/OttUniversalTable/OttUniversalTable";
import OttPaginationForTable from "@/components/OttUniversalTable/OttPaginationForTable";
import NoDataInTable from "@/components/NoDataInTable";
import FilterData from "./components/FilterData";
import PageLoader from "@/components/pageLoader/PageLoader";

export default {
  name: "ClientActivity",
  components: {
    PageLoader,
    NoDataInTable,
    OttPaginationForTable,
    OttUniversalTable,
    OttHideShowColumnsOption,
    OttCheckbox,
    OttRadio,
    OttSelect,
    OttButton,
    FilterData,
  },
  mixins: [ OttUniversalTableForComponentsMixin, FilterMixin, ColumnConfigMixin ],
  data() {
    return {
      defaultColumnConfigData: [],
      columnConfigData: [],
      loading: false,
    };
  },
  async created() {
    if (this.$route.name !== 'Search') {
      this.loading = true
      await this.getResellersList()
      await this.getClientActivitySettings()
    }
    this.createColumnConfigData()
    this.sortColumn()
    await this.getClientActivityList(this.clientActivity.filterData)
      .finally(() => this.loading = false);
    this.resetSelectedList()
  },
  beforeDestroy() {
    this.filterModel.reset()
    this.setData({ filterData: {} })
  },
  destroyed() {
    this.setFilterPage(1)
    this.clientActivityResetState()
  },
  computed: {
    ...mapState({
      clientActivity: (state) => state.clientActivityModule,
      dataTable: (state) => state.clientActivityModule.dataTable,
      isDefault: (state) => state.clientActivityModule.isDefault,
      filter: (state) => state.clientActivityModule.filter,
      filterModel: (state) => state.clientActivityModule.filterModel,
      defaultSettings: (state) => state.clientActivityModule.defaultSettings,
      updateColumnSettingsCommit: (state) => state.clientActivityModule.updateColumnSettingsCommit,
      updateColumnsFromBack: (state) => state.clientActivityModule.updateColumnsFromBack,
      resellersList: (state) => state.providers.resellersList,
      isTableLoading: (state) => state.clientActivityModule.isTableLoading,
      clientActivityResult: (state) => state.clientActivityModule.clientActivityResult,
      showColumnConfig: (state) => state.clientActivityModule.showColumnConfig,
    }),

    defaultColumnConfigs() {
      return [
        {
          title: "ID",
          isTranslate: false,
          style: { minWidth: 160 },
          key: "number",
          isShow: true,
          isDrag: true,
          defaultHide: true,
          defaultDragIndex: 0,
          mainIndex: 0,
          sortData: {
            isSort: true,
            sortKey: "number",
            sortValue: "",
          },
          cellView: (row) => `
            <p class="G-text-table">
              ${ row?.number ? row.number : '-' }
            </p>`,
        },
        {
          title: "Provider",
          isTranslate: false,
          style: { minWidth: 160 },
          key: "provider",
          isShow: true,
          isDrag: true,
          defaultHide: true,
          defaultDragIndex: 1,
          mainIndex: 1,
          sortData: {
            isSort: true,
            sortKey: "provider",
            sortValue: "",
          },
          cellView: (row) =>
            `<p class="G-text-table">${
              ('provider' in row) ? row.provider.name[0].name : '-'
            } </p>`,
        },
        {
          title: "Client",
          isTranslate: false,
          style: { minWidth: 160 },
          key: "client",
          isShow: true,
          isDrag: true,
          defaultHide: true,
          defaultDragIndex: 2,
          mainIndex: 2,
          sortData: {
            isSort: true,
            sortKey: "client",
            sortValue: "",
          },
          cellView: (row) =>
            `<p class="G-text-table">${
              ('client' in row) ? row.client?.personalInfo?.firstname + ' ' + row.client?.personalInfo?.lastname : '-'
            } </p>`,
        },
        {
          title: "User",
          isTranslate: false,
          style: { minWidth: 160 },
          key: "user",
          isShow: true,
          isDrag: true,
          defaultHide: true,
          defaultDragIndex: 3,
          mainIndex: 3,
          sortData: {
            isSort: true,
            sortKey: "user",
            sortValue: "",
          },
          cellView: (row) =>
           `<p class="G-text-table">${row.user?.firstname && row.user?.lastname ? row.user.firstname + " " + row.user.lastname : '-'}</p>`,
        },
        {
          title: "Actions",
          isTranslate: false,
          style: { minWidth: 300 },
          key: "action",
          isShow: true,
          isDrag: true,
          defaultHide: true,
          defaultDragIndex: 4,
          mainIndex: 4,
          sortData: {
            isSort: true,
            sortKey: "action",
            sortValue: "",
          },
          cellView: (row) => {
            let actionsTabel = '-'
            if (('action' in row) && Object.entries(row.action).length) {
              actionsTabel = '<table class="informations-table">'
              Object.keys(row.action).map(key =>
                actionsTabel += `
                  <tr class="mb-2">
                    <td class="G-text-table vertical-align-baseline py-1 text-capitalize">${ key }</td>
                    <td class="G-text-table vertical-align-baseline py-1">
                      ${ 
                        key === 'amount' 
                          ? 
                            this.numberToFinanceNumber(row.action[key])
                          : row.action[key] 
                        }
                  </tr>`
              )
              actionsTabel += '</table>'
              return actionsTabel
            } else {
              return '-'
            }
          }
        },
        {
          title: "Action Description",
          isTranslate: false,
          style: { minWidth: 200 },
          key: "actionDescription",
          isShow: true,
          isDrag: true,
          defaultHide: true,
          defaultDragIndex: 5,
          mainIndex: 5,
          sortData: {
            isSort: true,
            sortKey: "actionDescription",
            sortValue: "",
          },
          cellView: (row) =>
            `<p class="G-text-table">${
              row?.actionDescription ? row.actionDescription : '-'
            }</p>`,
        },
        {
          title: "Type",
          isTranslate: false,
          style: { minWidth: 160 },
          key: "type",
          isShow: true,
          isDrag: true,
          defaultHide: true,
          defaultDragIndex: 6,
          mainIndex: 6,
          sortData: {
            isSort: true,
            sortKey: "type",
            sortValue: "",
          },
          cellView: (row) =>
            `<p class="G-text-table">${
              row?.type ? row.type.split('_').join(' ') : '-'
            }</p>`,
        },
        {
          title: "Type Description",
          isTranslate: false,
          style: { minWidth: 200 },
          key: "typeDescription",
          isShow: true,
          isDrag: true,
          defaultHide: true,
          defaultDragIndex: 7,
          mainIndex: 7,
          sortData: {
            isSort: true,
            sortKey: "typeDescription",
            sortValue: "",
          },
          cellView: (row) =>
            `<p class="G-text-table">${
              row?.typeDescription ? row?.typeDescription : '-'
            }</p>`,
        },
        {
          title: "Date",
          isTranslate: false,
          style: { minWidth: 160 },
          key: "updatedAt",
          isShow: true,
          isDrag: true,
          defaultHide: true,
          defaultDragIndex: 8,
          mainIndex: 8,
          sortData: {
            isSort: true,
            sortKey: "updatedAt",
            sortValue: "",
          },
          cellView: (row) =>
            `<p class="G-text-table">${
              row?.updatedAt ? row.updatedAt : '-'
            }</p>`,
        },
      ]
    },

    rowsPerPage: {
      set(limit) {
        if (this.$route.name !== 'Search')
          this.setLimit(limit)
        else 
          this.setSearchLimit(limit)
      },
      get() {
        if (this.$route.name !== 'Search')
          return this.filter.limit;
        else  
          return this.filter.searchLimit;
      }
    },
  },
  methods: {
    ...mapActions({
      getClientActivityList: "clientActivityModule/getClientActivityList",
      getClientActivitySettings: "clientActivityModule/getClientActivitySettings",
      updateClientActivitySettings: "clientActivityModule/updateClientActivitySettings",
      getResellersList: "providers/getResellersList",
    }),
    ...mapMutations({
      setData: "clientActivityModule/setData",
      setLimit: "clientActivityModule/setLimit",
      setSearchLimit: "clientActivityModule/setSearchLimit",
      setFilterPage: "clientActivityModule/setFilterPage",
      sortClientActivity: "clientActivityModule/sortClientActivity",
      sortColumn: "clientActivityModule/sortColumn",
      filterClientActivity: "clientActivityModule/filterClientActivity",
      transactionsSetData: "transactionsModule/setData",
      shippingsSetData: "shippingsModule/setData",
      billsSetData: "clientBillsModule/setData",
      clientActivityResetState: "clientActivityModule/resetState",
      resetFilterModel: 'clientActivityModule/resetFilterModel',
    }),

    async resetDataTableSettings() {
      await this.resetDataTable()
      await this.getClientActivitySettings()
      this.createColumnConfigData()
    },

    async resetFilterData() {
      this.filterModel.reset();
      this.resetFilterModel()
      await this.getClientActivityList(this.clientActivity.filterData);
      this.resetSelectedList()
    },

    async sortColumnData(data) {
      this.filterClientActivity()
      this.sortColumn(data);
    },

    async updateLimit() {
      this.sortColumn()
      await this.$store.dispatch(this.updateColumnsFromBack, this.defaultSettings).then(() => {
        this.createSaveDefaultData();
      })
      await this.getClientActivityList(this.clientActivity.filterData);
      this.resetSelectedList()
    },

    async updateClientActivity(page) {
      if (page) {
        this.setFilterPage(page);
      }
      this.sortColumn();
      await this.getClientActivityList(this.clientActivity.filterData);
      this.resetSelectedList()
    },

    async changeActivityType(type) {
      this.filterClientActivity()
      this.sortColumn();
      await this.getClientActivityList(this.clientActivity.filterData);
      this.resetSelectedList()
    },

    hoverColumnConfig() {
      if (this.$route.name === 'Search') {
        this.transactionsSetData({ showColumnConfig: false })
        this.shippingsSetData({ showColumnConfig: false })
        this.billsSetData({ showColumnConfig: false })
      }
    },

    leaveColumnConfig() {
      if (this.$route.name === 'Search') {
        this.transactionsSetData({ showColumnConfig: true })
        this.shippingsSetData({ showColumnConfig: true })
        this.billsSetData({ showColumnConfig: true })
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/variables.scss";

.P-activity-list {
  position: relative;
}

.P-users-columns {
  padding-left: 10px;
}

.v-menu__content {
  max-width: 190px !important;
}
.approve-rej-icon-cont {
  padding-left: 10px;
  margin-left: 10px;
  border-left: 1.5px solid #a5aac6;
}

.P-small-header {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 10px;
  min-height: 40px;
  display: flex;
  align-items: center;
}

::v-deep {
  .v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
  }

  .v-text-field.v-text-field--enclosed .v-text-field__details {
    display: none;
  }
}

.P-radio-box + .P-radio-box {
  margin-left : 15px;
}
</style>

<style lang="scss">
@import "src/assets/scss/variables.scss";

  .vertical-align-baseline {
    vertical-align: baseline;
  }

  .P-activity-list {
    .informations-table {
      width: 100%;
      border-collapse: collapse;
      & tr {
        & td {
          border-bottom: 1px solid $neutral-color45;
        }
        & > td:nth-child(1) {
          // border-right: 1px solid $neutral-color;
        }
        & > td:nth-child(2) {
          padding-left: 12%;
        }

        &:last-child {
          & td {
            border-bottom: unset;
          }
        }
      }
    }
  }
</style>
