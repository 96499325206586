<template>
  <selected-filters 
    :selected-list="selectedList"
    @resetData="resetDataFunction($event)"
  />
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import SelectedFilters from "@/components/filterComponents/SelectedFilters";
import DateFormatMixin from "@/mixins/DateFormatMixin";

export default {
  components: { SelectedFilters },
  mixins: [ DateFormatMixin ],

  computed: {
    ...mapState({
      filterModel: (state) => state.clientActivityModule.filterModel,
      filter: (state) => state.clientActivityModule.filterData,
      resellersList: (state) => state.providers.resellersList,
    }),

    selectedList() {
      return [
        {
          ifCondition: !!this.filterModel.search,
          boxText: '<b>Search</b>: ' + this.filterModel.search,
          resetArgument: "search",
        },

        {
          ifCondition: this.filterModel.allResellers,
          boxText: `<b>All</b>: Yes`,
          resetArgument: "allResellers",
        },
        {
          ifCondition: this.filterModel.resellers.length,
          boxText: "<div class='G-flex-center'><span class='me-2'><b>Resellers</b>:</span> " + this.resellersNames,
          resetArgument: "resellers",
        },

        {
          ifCondition: !!(this.filterModel.date?.start && this.filterModel.date?.end),
          boxText: `<b>Activity Date</b>: ${this.getActivityDateValue}`,
          resetArgument: 'date'
        },
      ];
    },

    resellersNames() {
      let providersNames = '<span>'
      if (this.resellersList.length) {
        this.resellersList.map((i) => {
          this.filterModel.resellers.map((j, index) => {
            if(i.id === j) {
              providersNames += `${i.name}${this.filterModel.resellers.length > 1 ? '<br />' : ''}`
            }
          })
        })
      }
      providersNames += '</span>'

      return providersNames;
    },

    getActivityDateValue(){
      let value = ''
      if (this.filterModel.date?.start && this.filterModel.date?.end){
        value = this.$moment(this.filterModel.date.start).format(this.ottDateFormat).valueOf() + ' - ' + this.$moment(this.filterModel.date.end).format(this.ottDateFormat).valueOf()
      }
      return value
    },
  },

  methods: {
    ...mapActions({
      getClientActivityList: "clientActivityModule/getClientActivityList",
    }),

    ...mapMutations({
      filterClientActivity: "clientActivityModule/filterClientActivity",
    }),

    async resetDataFunction(resetArgument) {
      typeof resetArgument === 'string' ? await this.resetData(resetArgument) : await resetArgument()
    },

    async resetData(type) {
      this.filterModel.resetCurrentData(type);
      this.filterModel.getFilterCount();
      this.filterClientActivity()
      await this.getClientActivityList(this.filter);
    },
  },
};
</script>
